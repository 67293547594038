@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
  overflow-x: hidden;
}

body {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 500;
}

.font-roboto {
  font-family: 'Montserrat', sans-serif;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

*::-webkit-scrollbar {
  width: 8px;
  margin-left: 10px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 10px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* React Datepicker Styling */

.react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
  padding: 1.5rem 0 1rem 0;
}

.react-datepicker {
  font-family: 'Muli', sans-serif !important;
  font-size: 0.9rem;
  /* box-shadow: 1px 2px 15px 0 rgba(0, 0, 0, 0.2); */
  box-shadow: rgb(0 0 0 / 5%) 0px 5px 10px;
  border-radius: 10px;
  border: none;
}

.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
  border-top-color: rgb(131 131 131 / 28%);
  box-shadow: rgb(0 0 0 / 5%) 0px 5px 10px;
}

.react-datepicker__navigation {
  top: 1.5rem;
}

.react-datepicker__month {
  margin: 0.8rem;
}

.react-datepicker-popper {
  z-index: 1001;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: rgb(59, 130, 246);
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--in-range) {
  background-color: #5d95f6;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #5d95f6;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #5d95f6;
}

/* .react-datepicker__month-read-view {
  margin: 0 1.5rem;
} */

.react-datepicker__header__dropdown--scroll {
  margin: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  gap: 2rem;
  padding: 0.3rem 0;
  border-radius: 6px;
}

/* Editor */

.quill>.ql-toolbar.ql-snow {
  border: none !important;
  opacity: 0.8;
  position: absolute;
  bottom: 0;
}

.ql-toolbar.ql-snow+.ql-container.ql-snow {
  height: max-content;
  border: none;
  padding-bottom: 2.2rem;
}

.ql-editor.ql-blank::before {
  font-style: normal !important;
  font-family: 'Montserrat', sans-serif !important;
}

.-ml-160 {
  margin-left: -160px;
}

.assign-cal-wrapper .react-datepicker__input-container {
  display: none;
}

.assign2-cal-wrapper .assign-input {
  width: 8rem;
}

.assign3-cal-wrapper .assign-input {
  width: 5rem;
}

.assign3-cal-wrapper .assign-input,
.assign2-cal-wrapper .assign-input {
  border: none;
  cursor: pointer;
  color: black;
  padding: 0 !important;
  font-size: 0.8rem;

  text-decoration: underline;
  text-decoration-color: rgb(0, 0, 0);
  text-decoration-style: dashed;
  text-underline-offset: 6px;
}

.assign3-cal-wrapper .assign-input::placeholder,
.assign2-cal-wrapper .assign-input::placeholder {
  text-decoration: underline;
  text-decoration-color: rgb(0, 0, 0);
  text-decoration-style: dashed;
  text-underline-offset: 6px;
}

.assign3-cal-wrapper .assign-input:focus,
.assign2-cal-wrapper .assign-input:focus {
  outline: none;
  box-shadow: none;
}

.assign3-cal-wrapper .assign-input::placeholder,
.assign2-cal-wrapper .assign-input::placeholder {
  font-size: 0.8rem;
  color: black;
}

.assign-cal-wrapper {
  position: absolute;
}

.text-sm-max {
  font-size: 15px;
}

.requests-list-container::-webkit-scrollbar {
  width: 4px;
  margin-left: 10px;
}

.requests-list-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.requests-list-container::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 10px;
}

.noFlex {
  display: grid !important;
}

.bg-blurred {
  background: rgba(255, 255, 255, 0.5);
}

.entry-content,
.terms-entry-content {
  width: 60%;
  margin: 4rem auto;
  font-family: 'Montserrat', sans-serif;
  color: #4d4d4d;
}

.entry-content h2,
.terms-entry-content h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: 700;
  color: #383838;
}

.entry-content p,
.terms-entry-content p {
  margin: 1rem 0;
}

.entry-content ul,
.terms-entry-content ol {
  margin-left: 2rem;
}

.entry-content li,
.terms-entry-content li {
  list-style-type: disc;
}

@media (max-width: 991px) {

  .terms-entry-content,
  .entry-content {
    width: 90%;
    margin: 2rem 1rem;
  }

  .entry-content h2,
  .terms-entry-content h1 {
    font-size: 14px;
    margin-bottom: 0.5rem;
  }

  .entry-content p,
  .terms-entry-content p,
  .entry-content ul li,
  .terms-entry-content ul li {
    font-size: 12px;
  }
}

.stepper {
  display: flex;
  padding-left: 16px;
  width: 100%;
  list-style: none;
  position: relative;
  color: white;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    content: '';
    width: calc(100% - 20px);
    background: #fff;
  }
}

.stepper__item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 100%;
  padding: 10px;
  margin: 0 0 0 -14px;
  border-bottom: 5px solid white;
  clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
  /*moz*/
  -moz-clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
}

.stepper__item.pending {
  background: repeating-linear-gradient(-65deg, #f3f56d, #f3f56d 20px, #f3f56d 20px, #f3f56d 40px);
  color: rgb(49, 49, 49);
}

.stepper__item.current {
  background: #fff;
  font-weight: bold;
  color: rgb(49, 49, 49);
}

.stepper__item.complete {
  background: repeating-linear-gradient(-65deg, #67b875, #67b875 20px, #67b875 20px, #67b875 40px);
  color: white;
}

.stepper__item.current_complete {
  background: #67b875;
  border-bottom: 5px solid rgb(90, 90, 90);
  color: white;
}

.stepper__item.current_pending {
  background: #f3f56d;
  color: rgb(49, 49, 49);
  border-bottom: 5px solid rgb(90, 90, 90);
}

.stepper__item:first-child {
  padding: 10px;
  clip-path: polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
  -moz-clip-path: polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
}

.stepper__item:last-child {
  clip-path: polygon(20px 50%, 0% 0%, 100% 0%, 100% 100%, 0% 100%);
  -moz-clip-path: polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
}

.rbc-event-content,
.rbc-event-label {
  color: #222222;
}

.as-dropdown-container button {
  box-shadow: none !important;
}

.Item_item__34h1P:hover>div {
  flex-direction: row !important;
}

.invite_modal_datepicker .react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__month-select ,
.react-datepicker__year-select{
  font-size: 12px; 
  border: 1px solid #ccc;
  border-radius: 5px;  
  background-color: #fff;

}

.react-datepicker__header,
.react-datepicker__month {
  background-color: #fff;
  margin: 0px;
  font-size: 12px;
}
.react-datepicker__header {
  border-left: 1px solid #d5d5d5;
  border-right: 1px solid #d5d5d5;
  border-top: 1px solid #d5d5d5;
}
.react-datepicker__month{
  border-left: 1px solid #d5d5d5;
  border-right: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
  border-radius: 0 0 5px;
}


#action-undo,
#action-redo,
#svd-save,
#svd-settings,
.sv-action-bar-separator {
  display: none !important;
}