._relative {
  position: fixed;
}

._overflow {
  overflow: hidden;
}

._overflow:hover ._absolute {
  display: block;
}
._absolute {
  width: 200px;
  height: 60px;
  background: #f00;
  position: absolute;
  top: 20px;
  left: 30px;
  display: none;
}
