/* 
  You want a simple and fancy tooltip?
  Just copy all [data-tooltip] blocks:
*/
[data-tooltip] {
  position: relative;
  z-index: 999;
}

.tooltip-btn {
  margin: 0;

  -webkit-appearance: none;
  -moz-appearance: none;
  transition: background 350ms ease-in-out, transform 150ms ease;
}

.tooltip-btn::-moz-focus-inner {
  border: 0;
}

/* Positioning and visibility settings of the tooltip */
[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  bottom: calc(100% + 5px);
  /* 5px is the size of the arrow */
  pointer-events: none;
  transition: 0.2s;
  will-change: transform;
}

/* The actual tooltip with a dynamic width */
[data-tooltip]:before {
  content: attr(data-tooltip);
  padding: 7px 18px;
  min-width: 50px;
  max-width: 300px;
  width: max-content;
  width: -moz-max-content;
  border-radius: 6px;
  font-size: 14px;
  background-color: rgba(59, 72, 80, 0.9);
  background-image: linear-gradient(30deg, rgba(59, 72, 80, 0.44), rgba(59, 68, 75, 0.44), rgba(60, 82, 88, 0.44));
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  white-space: pre-wrap;
  transform: translate(-50%, -5px) scale(0.5);
}

/* Tooltip becomes visible at hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}

/* Scales from 0.5 to 1 -> grow effect */
[data-tooltip]:hover:before {
  transition-delay: 0.3s;
  transform: translate(-50%, -5px) scale(1);
}

/* 
    Arrow slide down effect only on mouseenter (NOT on mouseleave)
  */
[data-tooltip]:hover:after {
  transition-delay: 0.3s;
  /* Starting after the grow effect */
  transition-duration: 0.2s;
  transform: translateX(-50%) scaleY(1);
}

/*
    That's it for the basic tooltip.
  
    If you want some adjustability
    here are some orientation settings you can use:
  */

/* LEFT */
/* Tooltip + arrow */
[data-tooltip-location='left']:before,
[data-tooltip-location='left']:after {
  left: auto;
  right: calc(100% + 5px);
  bottom: 50%;
}

/* Tooltip */
[data-tooltip-location='left']:before {
  transform: translate(-5px, 50%) scale(0.95);
}

[data-tooltip-location='left']:hover:before {
  transform: translate(-5px, 50%) scale(1);
}

/* Arrow */
[data-tooltip-location='left']:after {
  border-width: 5px 0px 5px 5px;
  border-color: transparent transparent transparent rgba(55, 64, 70, 0.9);
  transform-origin: left;
  transform: translateY(50%) scaleX(0);
}

[data-tooltip-location='left']:hover:after {
  transform: translateY(50%) scaleX(1);
}

/* RIGHT */
[data-tooltip-location='right']:before,
[data-tooltip-location='right']:after {
  left: calc(100% + 5px);
  bottom: 50%;
}

[data-tooltip-location='right']:before {
  transform: translate(5px, 50%) scale(0.5);
}

[data-tooltip-location='right']:hover:before {
  transform: translate(5px, 50%) scale(1);
}

[data-tooltip-location='right']:after {
  border-width: 5px 5px 5px 0px;
  border-color: transparent rgba(55, 64, 70, 0.9) transparent transparent;
  transform-origin: right;
  transform: translateY(50%) scaleX(0);
}

[data-tooltip-location='right']:hover:after {
  transform: translateY(50%) scaleX(1);
}

/* BOTTOM */
[data-tooltip-location='bottom']:before,
[data-tooltip-location='bottom']:after {
  top: calc(100% + 5px);
  bottom: auto;
}

[data-tooltip-location='bottom']:before {
  transform: translate(-50%, 5px) scale(0.5);
}

[data-tooltip-location='bottom']:hover:before {
  transform: translate(-50%, 5px) scale(1);
}

[data-tooltip-location='bottom']:after {
  border-width: 0px 5px 5px 5px;
  border-color: transparent transparent rgba(55, 64, 70, 0.9) transparent;
  transform-origin: bottom;
}

@keyframes moveFocus {
  0% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 100% 0%;
  }
}

/* Thumbnail settings */
@media (max-width: 750px) {
  [data-tooltip]:after {
    bottom: calc(100% + 3px);
  }

  [data-tooltip]:after {
    border-width: 7px 7px 0px 7px;
  }
}


[data-tooltip-margin='0 0 0 3rem']::before {
  margin: 0 0 0 3rem;
}