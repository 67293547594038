@import 'variables';
@import 'common';
@import 'atoms/index.scss';
@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles';

.rbc-toolbar {
    .rbc-toolbar-label {
        font-weight: 600;
    }

    & button {
        color: #777;
        font-weight: 600;
        font-size: 14px;
        background-color: #F3F3F3;

        &:active,
        &.rbc-active {
            background-color: rgb(59, 130, 246);
            border-color: rgb(59, 130, 246);
            color: #fff;

            &:hover,
            &:focus {
                background-color: rgb(59, 130, 246);
                border-color: rgb(59, 130, 246);
                color: #fff;
            }
        }

        &:hover,
        &:focus {
            background-color: #F3F3F3;
            border-color: rgb(59, 130, 246);
            color: rgb(59, 130, 246);
        }
    }

    .rbc-btn-group {
        button {
            outline: none;
        }
    }
}

.rbc-off-range-bg {
    background: #F3F3F3;
}

.rbc-off-range {
    color: #999;
    font-weight: 600;
}

.rbc-button-link {
    margin-top: 5px;
    font-weight: 600;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 100%;
}

.rbc-month-view {
    border-radius: 10px;

    .rbc-header {
        >&:first-child {
            border-top-left-radius: 10px;
        }

        >&:last-child {
            border-top-right-radius: 10px;
        }
    }
}

.rbc-header {
    padding: 1rem 3px;
    background-color: rgb(59, 130, 246);
    color: #eee;
    font-weight: 600;
}

.rbc-header+.rbc-header {
    border-left: none;
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event,
.rbc-event,
.rbc-background-event {
    border: 1px solid rgb(59, 130, 246);
    background: #fdefef;
    color: rgb(59, 130, 246);
    font-size: 10px;
}

.rbc-show-more {
    font-size: 12px;
    font-weight: 600;
}

.rbc-time-gutter {
    background: #F3F3F3;
    color: #777;

    .rbc-label {
        padding: 0 14px;
        margin-top: 5px;
        font-size: 12px;
    }
}

.rbc-agenda-date-cell {
    background: #F3F3F3;
    color: #777;
}

.rbc-agenda-time-cell {
    color: #444;
}

.rbc-agenda-event-cell {
    color: #444;
    cursor: pointer;
}

.rbc-event.rbc-selected {
    position: relative;
}

.rbc-agenda-view,
.rbc-time-view {
    border-radius: 10px;
}

.rbc-calendar {
    font-size: 14px;
}

.rbc-toolbar .inline-block button {
    display: flex !important;
    margin-right: 20px;
}

.react-calendar-timeline .rct-header-root {
    background: rgb(59, 130, 246) !important;
    border-radius: 6px;
}

.react-calendar-timeline .rct-calendar-header {
    border: none !important;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
    color: #525252;
    font-size: 14px;
    padding-left: 10px !important;
}

.react-calendar-timeline .rct-dateHeader {
    color: #525252 !important;
}

.react-calendar-timeline .rct-dateHeader-primary {
    color: #fff !important;
    font-weight: 600;
}

.inputWidth input {
    width: 5rem !important;
}

.appraisal-tooltip {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.rbc-toolbar .rbc-toolbar-label {
    margin-left: 127px;
}

#skip-level-block > div {
    button + div {
        left: 55px;
    }
}