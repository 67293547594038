table {
  thead {
    th {
      border-top: 0px !important;
    }
  }
}

$base-line-height: 18px;
$white: rgb(255, 255, 255);
$off-white: rgba($white, 0.2);
$spin-duration: 1s;
$pulse-duration: 750ms;

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  50% {
    background: $white;
  }
}

.loading {
  border-radius: 50%;
  width: $base-line-height;
  height: $base-line-height;
  border: 0.15rem solid $off-white;
  border-top-color: $white;
  animation: spin $spin-duration infinite linear;
}

.fixed-nav__btn {
  &.active {
    &::before {
      content: '';
      height: 100%;
      position: absolute;
      width: 1px;
      left: -15px;
      top: 0;
      animation: leftBorder 0.3s ease-in-out;
    }
  }
}

.path-2 {
  content: '';
  width: 2px;
  margin-right: 10px;
  background-color: $green;
}

@keyframes leftBorder {
  from {
    height: 0%;
  }

  to {
    height: 100%;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.home__profile_image {
  overflow: hidden;

  >img {
    scale: 1.01;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    >img {
      scale: 1.1;
    }
  }
}

.employee_dropdown3 {
  ul {
    max-height: 340px;
    overflow-y: scroll;
  }
}

.ht_verticalLine {
  left: 5px !important;
  margin-left: -15px !important;

  >svg {
    >line {
      stroke: rgb(179, 179, 179) !important;
      stroke-dasharray: unset !important;
    }
  }
}

.ht_horizontalLine {
  width: 29px !important;
  left: -29px !important;

  >svg {
    >line {
      stroke: rgb(179, 179, 179) !important;
      stroke-dasharray: unset !important;
    }
  }
}

.tree-node {
  display: flex;
  align-items: center;
  min-height: 50px;
  max-height: 50px;
}

.tree-node .tree-icon.close-icon {
  background-image: url(https://alekseymakhankov.github.io/packages/static/media/minus.77d09f6f.svg);
  width: 12px;
  height: 12px;
  background-size: contain;
}

.tree-node .tree-icon.open-icon {
  background-image: url(https://alekseymakhankov.github.io/packages/static/media/plus.721c19f2.svg);
  width: 12px;
  height: 12px;
  background-size: contain;
}

.tree-node .tree-icon {
  margin-right: 2px;
  margin-left: -18px;
  border-radius: 50%;
  cursor: pointer;
  background-repeat: no-repeat;
}

.tree-node .node-content-wrapper {
  cursor: pointer;
  min-height: 40px;
  max-height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  padding: 0 6px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.node-selected {
  background-color: rgb(59, 130, 246);
  border: 1px dashed rgb(17, 41, 80);
  color: #fff;

  svg {
    stroke: #fff;
  }
}

.ht_selected {
  background-color: #fff !important;
}

.file__list {
  .icon__options {
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    .icon__options {
      opacity: 1;
    }
  }
}

#react-doc-viewer {
  background-color: transparent;
  color: #fff;

  #html-body {
    border-color: #fff;
    background-color: #fff;
    border-radius: 10px;
  }

  #doc-nav-prev,
  #doc-nav-next {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(118, 118, 118, 0.7);
    backdrop-filter: blur(1px);
  }

  #doc-nav-prev[disabled],
  #doc-nav-next[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .doc-nav-info {
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    align-items: center;
    z-index: 1;
    padding: 0px 10px;
    background-color: rgb(255, 255, 255);
    font-size: 16px;
    min-height: 50px;
  }

  #msdoc-renderer,
  #pdf-renderer,
  #image-renderer {
    background-image: none;
    background-color: transparent;
    height: 420px;

    @media (min-width: 1400px) {
      height: 500px;
    }
  }

  #pdf-controls {
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
  }

  #doc-nav,
  #file-name {
    color: #fff;
    font-weight: 500;
  }

  #header-bar {
    color: #fff;
    background-color: transparent;
  }
}

.file__close {
  .close__icon {
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
  }

  &:hover {
    .close__icon {
      opacity: 1;
    }
  }
}

.moderator-dropdown {
  button {
    padding: 0 !important;
  }
}

.fullHeight {
  height: calc(100vh - 180px);
}

.quill {
  font-family: 'Montserrat', sans-serif;

  .ql-snow .ql-editor {

    p,
    span,
    li,
    ul {
      font-size: 12px;
      font-family: 'Montserrat', sans-serif;
    }
  }

  .ql-snow .ql-editor {

    h1,
    h2 {
      font-size: 14px;
      font-weight: 600;
      font-family: 'Montserrat', sans-serif;
    }
  }
}

.guidance_template {
  font-size: 14px;

  p {
    margin: 0;
  }

  h2 {
    font-weight: 700;
  }

  ul {
    --tw-space-y-reverse: 0;
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
    list-style-type: disc;
    margin-left: 2rem;
  }

  .ql-indent-1 {
    margin-left: 4rem;
    list-style-type: circle;
  }
}

.noScrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.circular-text {
  fill: currentColor;
  height: auto;
  max-width: 100vmin;
  transform-origin: center;
  width: 100%;
  position: absolute;
  top: 0;
  padding: 3px;
}

.tasks_slider {
  .rangeslider-horizontal .rangeslider__handle:after {
    display: none;
  }

  .rangeslider-horizontal .rangeslider__handle {
    width: 20px;
    height: 20px;
  }
}