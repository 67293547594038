html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
}

#root {
  margin: 0;
  height: 100%;
  width: 100%;
}

#react-org-chart {
  margin: 0;
  cursor: move;
  height: 100%;
  width: 100%;
  background-color: rgb(245, 255, 255);
}
.org-chart-person-name {
  font-weight: 500;
}
.org-chart-person-link:hover g {
  fill: #409cf9 !important;
}
.org-chart-node:hover .org-chart-person-reports {
  fill: #409cf9 !important;
}
.org-chart-person-dept.engineering {
  fill: #4caf50 !important;
}
.org-chart-person-dept.communications {
  fill: #3f51b5 !important;
}
.org-chart-person-dept.product {
  fill: #d500f9 !important;
}
.org-chart-person-dept.hr {
  fill: #2196f3 !important;
}
.org-chart-person-dept.marketing {
  fill: #f44336 !important;
}
.org-chart-person-dept.design {
  fill: #26c6da !important;
}