.sidebar {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: $greenSecondary;
  }

  &::-webkit-scrollbar-track:hover {
    background: #78797a;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(31, 41, 55);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgb(31, 41, 55);
  }
}
