thead tr {
  width: 97%;
  width: -webkit-calc(100% - 16px);
  width: -moz-calc(100% - 16px);
  width: calc(100% - 16px);
}

tr:after {
  content: ' ';
  display: block;
  visibility: hidden;
  clear: both;
}